import './style.css';
import React, { useState } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCreditCard} from '@fortawesome/free-solid-svg-icons'; 
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import BlockRevealAnimation from 'react-block-reveal-animation';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import Img from "./img/hsy-kizuna.jpg";
import Vid from "./img/hsy-0411-B.mp4";
import Notice4img from "./img/hsygoods.jpeg";

class App extends React.Component {  
  render(){
    function handleClickNotice1() {
      $("#modal-1").css("display", "flex");
    }
    function handleClickNotice2() {
      $("#modal-2").css("display", "flex");
    }
    function handleClickNotice3() {
      $("#modal-3").css("display", "flex");
    }
    function handleClickNotice4() {
      $("#modal-4").css("display", "flex");
    }
    return (
  <div className='Background slide'>
    <div className='TopNav'>
      <ul>
        <li><a href='https://t.livepocket.jp/e/np2skc' target='_blank' >チケット<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a></li>
        <li><a href='https://www.jec.or.jp/access.html' target='_blank' >東京会場<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a></li>
        <li><a href='https://www.ih-osaka.or.jp/access/' target='_blank' >大阪会場<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a></li>
      </ul>
    </div>  
    <div className='BodySection'>
      <div className='TextBox' id='fanmeeting2022'>
        <BlockRevealAnimation delay={0.25} duration={0.5} color="#fff">
          <div className='TitleBox borderBottomPink'>
            <BlockRevealAnimation delay={1} duration={1} color="#be957a">
              <h1 className='titleName'>KARA ハン・スンヨン</h1>
            </BlockRevealAnimation>
            <BlockRevealAnimation delay={1.25} duration={1} color="#be957a">
              <h1 className='titleSub'>JAPAN 2023</h1>
              <span className='breakMB'/>
              <h1 className='titleSub'>ファンミーティングツアー </h1>
            </BlockRevealAnimation>
            <BlockRevealAnimation delay={1.5} duration={1} color="#be957a">
              <h1 className='titleSub'>『 絆　第1章 』 </h1>
            </BlockRevealAnimation>
          </div>
        </BlockRevealAnimation>
        <div>
          <div className='content-text'>
            <SimpleSlider></SimpleSlider>
            <div className="noticeContainer">
              <h2>NEWS</h2>
              <p></p>
              <ul>
                <li id="notice-4" onClick={handleClickNotice4}>ハン・スンヨンJAPAN 2023 ファンミーティングツアー『 絆 第1章 』 にてグッズの販売が決定</li>
                <li id="notice-1" onClick={handleClickNotice1}>５/３(水曜日)機材席解放につき、先着受付の追加販売が決定</li>
                <li id="notice-2" onClick={handleClickNotice2}>東京と大阪公演のリハーサル観覧特典会、及び公演に関するお知らせ</li>
                <li id="notice-3" onClick={handleClickNotice3}>楽屋花、スタンド花のご案内</li>
                
              </ul>
            </div>
            <ArticleText></ArticleText>
            <BlockRevealAnimation delay={1} duration={2} color="#fff">
              <img src={ require('./img/hsy001.jpeg') } className="addImg" />
            </BlockRevealAnimation>
            <div className='paragraph'>
                <div className="v-container">
                  <ReactPlayer
                      className='react-player'
                      url={Vid}
                      playing={true}
                      controls={true}
                      loop={true}
                      muted={false}
                      light={Img}
                      width='100%'
                      height='100%'
                  />
                </div>
                <h2>日程</h2>
              <p>
              2023年05月03日（水・祝）<br/>
              <a href='https://www.jec.or.jp/access.html' target='_blank' className='btn m-bt-25'>東京 『一ツ橋ホール』<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a><span className='breakMB'/>
              <br/>
              1部）13時開場／14時開演  SHOW TIME 120min<br/>
              2部）17時開場／18時開演  SHOW TIME 120min<br/>
              <br/>
              2023年05月05日（金・祝）<br/>
              <a href='https://www.ih-osaka.or.jp/access/' target='_blank' className='btn m-bt-25'>大阪 『大阪国際交流センター』<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a><span className='breakMB'/>
              <br/>
              1部）13時開場／14時開演  SHOW TIME 120min<br/>
              2部）17時開場／18時開演  SHOW TIME 120min<br/>
              </p>
            </div>
            <div className='paragraph'>
              <h2>チケット</h2>
              <p>
              11,000円（税込）<br/>
              当日 12,000円（税込）<span className='breakMB'/>※発生した場合のみ　
              <a href='https://t.livepocket.jp/e/np2skc' target='_blank' className='btn'>購入先<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a><br/>
              </p>
            </div>
            
            <div className='paragraph'>
              <h2>プレイガイド</h2>
              <p>
              ・オフィシャルSNS先行<br/>
              　03月11日(土) 正午より販売予定 ※抽選<br/><br/>
              ・先行受付<br/>
              　03月24日(金) 15時より販売予定 ※抽選<br/><br/>
              ・一般発売 <br/>
              　04月08日(土) 午前10時より販売予定<br/><br/>
              <span className='keypoint'>※Live Pocket 、チケットぴあ、イープラス、ローソンチケットにて販売</span>
              </p>
            </div>

            <div className='paragraph'>
              <h2>問い合わせ</h2>
              <p>
              ハン・スンヨン JAPAN 2023 ファンミーティングツアー事務局<br/>
              電話 03-3263-7431（月〜金 午前10時~18時）<br/>
              </p>
            </div>

            <div className='paragraph'>
              <h2>オフィシャルSNS</h2>
              <p>
              <a href='https://www.instagram.com/thesy88' target='_blank'><FontAwesomeIcon icon={ faInstagram } />
              https://www.instagram.com/thesy88/</a><br/>
              <a href='https://twitter.com/fateflysy' target='_blank'><FontAwesomeIcon icon={ faTwitter } />
              https://twitter.com/fateflysy</a>
              </p>
            </div>
          </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  <img src={ require('./img/back1.png') } className="backImg1" />
  <img src={ require('./img/back2.png') } className="backImg2" />
  <Notice1 />
  <Notice2 />
  <Notice3 />
  <Notice4 />
</div>

  );
}
}

function Float() {
  return(
    <div>
      <a className='Floating' href='https://t.livepocket.jp/e/np2skc'><FontAwesomeIcon icon={ faCreditCard } />　チケットはこちらへ</a>
    </div>
  )
}

function PhotoSection() {
  return(
    <div className='photosection'>

    </div>
  )
}
function Iconset() {
  return(
    <div className='RightIcon'>
      <a href='https://www.instagram.com/thesy88' target='_blank'><FontAwesomeIcon icon={ faInstagram } /></a>
      <a href='https://twitter.com/fateflysy' target='_blank'><FontAwesomeIcon icon={ faTwitter } /></a>
    </div>
  )
}
function ArticleText() {
  return(
    <div className='articleText'>
      「ハン・スンヨン」ファンミーティングツアー5月3日東京・5日大阪で開催決定<br/>
      先日、KARA完全体で素晴らしいパフォーマンスを披露してくれたハン・スンヨンの、2023年初の単独ファンミーティング『絆　第1章』が、5月3日からを皮切りにスタートします。<br/><br/>
      これまでの長い間応援してくれた日本の多くのファンへの感謝の気持ちを伝えるための、今回のファンミーティングは、さらに特別な思い出を一緒に作れる様に、スペシャルなステージを計画中です。<br/>
      <ul>
        <li>①公演終了後に来場者全員を本人がお見送り致します。</li>
        <li>②1部と2部、両部を購入の方へは1部で15分間のリハーサル観覧をプレゼント。<br/>
            　(指定された席にて)</li>
        <li>③公演中、各自のスマートフォンでの写真及び撮影タイムがあります。</li>
        <li>④各回ごとの公演中に抽選でサインポスターをプレゼント。</li>
      </ul>
      <span className='keypoint'>
      ※スペシャル特典として<br/>
        　各回ごとに抽選で10名様にツーショット撮影が当たります！
      </span>
    </div>
  )
}

function Notice1() {
  return(
    <div className="modalContainer" id="modal-2">
      <div className="modalWrapperContainer">
        <div className="modalClose"></div>
        <div className="modalWrapper">
          <div className="modalTitle">
            『ハン・スンヨン2023 Japan Fan meeting tours絆　第1章』　東京と大阪公演のリハーサル観覧特典会、及び公演に関するお知らせ！！<br/>
          </div>
          <div className="modalText">
            東京と大阪公演の１部、2部両方のチケットをご購入した方に対するリハーサル観覧特典会の詳細をご案内いたします。<br/>
            <br/>
            東京<br/>
            集合時間：12時~12時半<br/>
            集合場所：一ツ橋ホール建物1階セブンイレブン右手にある敷地内駐車スペース<br/>
            <br/>
            大阪<br/>
            集合時間：12時~12時半（進行が遅れた場合、時間が変更になる場合があります。その場合は現地にて広報させていただきます）<br/>
            集合場所：公演場入口前<br/>
            <br/>
            注意事項<br/>
            <ul className="listNumeric">
              <li>集合時間を過ぎるとリハーサルへの入場対応が出来なくなりますので、ご注意ください。</li>
              <li>リハーサル入場時に昼公演のチケットを読み込みます。</li>
              <li>ご家族・ご友人を含め第三者に売買・譲渡することは一切禁止です。発覚した場合は参加をお断りさせていただきます。</li>
              <li>リハーサルにはご本人様以外は特典会にご参加いただけず、ご本人様以外の参加が判明した際は、退出していただいた上で以後の特典会には参加不可の対応を行いますのでご了承ください。</li>
              <li>リハーサル鑑賞時は、公演とは異なりお静かにご鑑賞頂くよう、お願いいたします。また、写真撮影はお控えください。</li>
              <li>公演中のプレゼント等にご当選された場合は、必ずご本人様が参加ください。第三者に売買・譲渡することは一切禁止です。</li>
              <li>万が一発見した場合は退場する場合がありますので、ご了承お願いします。</li>
              <li>小学生以下の方が特典会へご参加の場合、特典会の当日の受付時には保護者の方が同伴してください。</li>
              <li>当日の特典会及び公演中、取材等によるカメラが入る可能性があります。お客様が映りこむ可能性がありますので、予めご了承ください。</li>
              <li>以上、皆様のご理解・ご協力のほど何卒よろしくお願い申し上げます。</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

function Notice2() {
  return(
    <div className="modalContainer" id="modal-1">
      <div className="modalWrapperContainer">
        <div className="modalClose"></div>
        <div className="modalWrapper">
          <div className="modalTitle">
            ５/３(水曜日)『ハン・スンヨン2023 Japan Fanmeeting tours絆　第1章』　機材席解放につき、先着受付の追加販売が決定！<br/>
          </div>
          <div className="modalText">
          ５月3日(水)、一ツ橋ホールで開催予定の『ハン・スンヨン2023 Japan Fanmeeting tours絆　第1章』において、機材調整等により、若干枚数を追加席として販売させていただくことになりました。<br/><br/>
          本日4月21日(金)12:00より、先着順での販売となります。<br/><br/>
          ★チケットご購入(先着追加販売)はこちら<br/>
          <a href="https://t.livepocket.jp/e/w8b4k">https://t.livepocket.jp/e/w8b4k</a><br/>
          ※LivePocketのみでの受付となります。予定枚数に達し次第受付終了となりますので、予めご了承ください。<br/>
            <br/>
          </div>
        </div>
      </div>
    </div>
  )
}

function Notice3() {
  return(
    <div className="modalContainer" id="modal-3">
      <div className="modalWrapperContainer">
        <div className="modalClose"></div>
        <div className="modalWrapper">
          <div className="modalTitle">
            楽屋花、スタンド花のご案内<br/>
          </div>
          <div className="modalText">
            <h3>東京　一ツ橋ホール</h3>
            <hr />
            ＜スタンド花＞<br/>
            <hr />
            ■送付先■<br/>
            〒101-0003<br/>
            東京都千代田区一ツ橋2-6-2<br/>
            日本教育会館１階　画廊スペース<br/>
            TEL: 03-3230-2831<br/>
            ５/３ ハン・スンヨン2023 Japan Fanmeeting tours絆　第1章公演　スタンド花<br/>
            納品時間　：　5月3日（水）午前中必着<br/>
            回収時間　：　公演当日20時~20時30分までに必ず回収していただける業者にて<br/>
                              　　ご手配をお願いいたします。<br/>
                              <br/>
                              <br/>
            <hr />
            ＜楽屋花＞<br/>
            <hr />
            ■送付先<br/>
            〒101-0003<br/>
            東京都千代田区一ツ橋2-6-2<br/>
            日本教育会館の３階一ツ橋ホール<br/>
            気付：サイトエー　御中<br/>
            ５/３ ハン・スンヨン2023 Japan Fanmeeting tours 絆　第1章公演　楽屋花<br/>
            TEL: 03-3230-2831<br/>
            納品時間　：　5月3日（水）午前中必着<br/>
            <br/>
            <br/>
            <h3>大阪　大阪国際交流センター</h3>
            <hr />
            ＜スタンド花＞<br/>
            <hr />
            ■送付先■<br/>
            〒543-0001<br/>
            大阪府大阪市天王寺区上本町8丁目2−6 <br/>
            大阪国際交流センター<br/>
            TEL: 06-6772-5931<br/>
            ５/５ ハン・スンヨン2023 Japan Fanmeeting tours絆　第1章公演　スタンド花<br/>
            納品時間　：　5月5日（金）午前中必着<br/>
            回収時間　：　公演当日20時~20時30分までに必ず回収していただける業者にて<br/>
                              　　ご手配をお願いいたします。<br/>
                              <br/>
            <hr />
            ＜楽屋花＞<br/>
            <hr />
            ■送付先<br/>
            〒543-0001<br/>
            大阪府大阪市天王寺区上本町8丁目2−6 <br/>
            大阪国際交流センター<br/>
            気付：サイトエー　御中<br/>
            ５/５ ハン・スンヨン2023 Japan Fanmeeting tours 絆　第1章公演　楽屋花<br/>
            TEL: 06-6772-5931<br/>
            納品時間　：　5月5日（金）午前中必着<br/>
            <br/><br/><br/>
            <hr />
            注意事項<br/>
            <hr />
            ※すべて（スタンド花・バルーンアート種類問わず）必ず回収をしていただける<br/>
            　業者にてご手配ください。<br/>
            ※会場の展示場所が限られている為、設置後、一人で移動ができないような<br/>
            　サイズ・ボリュームのスタンド花・バルーンアートはご遠慮ください。　　<br/>
            ※付属物含め、複数のスタンド花・バルーンアートをつなげて設置することはできません。<br/>
            ※ロビーに展示するスタンド花・バルーンアートがあまりに大きい場合、<br/>
            　納品時にお断りする場合もありますので、予めご了承ください。<br/>
            <br/>
            <br/>
            【楽屋花】<br/>
            ※設置スペースの都合上、楽屋花（アレンジメント）のみのお受入れとさせていただきます。<br/>
            ※楽屋宛のバルーンその他(アレンジメントに花以外のものが含まれること)は<br/>
            　 ご遠慮いただけますようお願いいたします。<br/>
            ※楽屋花は机の上に並べさせていただきますので、あまり大きなものもご遠慮ください。<br/>
            ※楽屋花の回収は不要です。<br/>
            <br/>
            【その他】<br/>
            ※スタンド花・バルーンアート・楽屋花の搬入・設置・回収対応は、専門業者の方のみと<br/>
            　　させていただきます。　<br/>
            　　(業者の方お一人で対応可能なもののみお受入れ/楽屋花は回収不要)<br/>
            ※業者以外の方（発注者ご本人様含む）の同行及び設置・回収等の作業、<br/>
            　　送付いただいた花･バルーン及び付属物をご自身でお持ち帰りいただくこと、<br/>
            　　解体し配布すること等は全て禁止とさせていただきます。<br/>
            ※ルールに反していると判断した場合は、当日受け入れをお断りする可能性がございますので、<br/>
            　　ご注意ください。<br/>
            ※ロビー展示に関しましては、夜公演当日・開演30分後に必ず回収していただく条件で<br/>
            　お受けいたしますので、ご協力願います。<br/>
            ※ロビーに展示するものですが、「スタンド花・バルーンアート」以外のものはご遠慮ください。<br/>
            <br/>
          </div>
        </div>
      </div>
    </div>
  )
}
function Notice4() {
  return(
    <div className="modalContainer" id="modal-4">
      <div className="modalWrapperContainer maxWidth800">
        <div className="modalClose"></div>
        <div className="modalWrapper">
          <div className="modalTitle">
            ハン・スンヨンJAPAN 2023 ファンミーティングツアー『 絆  第1章 』 にてグッズの販売が決定いたしました！<br/>
          </div>
          <div className="modalText">
            【ラインナップ】<br/>
            <a href={Notice4img} target="_blank"><img src={Notice4img} className="width100" /></a>
            <br/>
            ※表示は全て税込価格です。イメージは色・デザインなど実物と多少異なる可能性がございます。予めご了承ください。<br/>
            ※商品の販売は予定数に達し次第、終了とさせていただきます。<br/>
            ※不良品の交換は当日会場内でのみ承ります。ご購入後は速やかに商品をご確認ください。（イニシャルキーリング、ストラップの文字印刷が剝がれている場合がございますが不良品ではありませんのでご了承お願いします。）<br/>
            ※お支払いは現金のみとなります。予めご了承ください。<br/>
            <br/>
            <br/>
            <strong>☆★KIZUNAグッズ特典★☆</strong><br/>
            <br/>
            <strong>❋ Complet Set</strong> （数量限定）　¥28,800<br/>
            商品　： 全商品1個入りセット<br/>
            特典　： 1) サイン入りポスター抽選券1枚<br/>
            　　　　 2) ランダム生写真4種入り<br/>
            　　　　 3) サイン入りグッズ抽選券 1枚<br/>
            <br/><br/>
            <strong>❋ 応援セット</strong>（数量限定）　¥9,900<br/>
            商品　： ペンライト、マフラータオル、T-シャツ、PVうちわ<br/>
            特典　： 1)ランダム生写真２種入り<br/>
            　　　　 2)サイン入りグッズ抽選券２枚<br/>
            <br/><br/>
            <strong>❋ 会場特典</strong><br/>
            当日会場1度のお会計金額<strong>10,000円</strong>ごとに貰えるプレゼント！！！<br/>
            特典　： 1)ランダム生写真１種入り<br/>
            　　　　 2)サイン入りグッズ抽選券 1枚<br/>
            <br/>
            <br/>
            ※ 会場特典の対象金額は、complete setや応援セットを含めない、その他購入グッズ商品の合計金額が対象になります。<br/>
            ※ 特典会は、特典が無くなり次第終了になりますのでご了承ください。<br/>
            <br/>
            【販売時間】<br/>
            東京／一ツ橋ホール 1Fロービ<br/>
            大阪／大阪国際交流センタ ー入口<br/>
            <br/>
            先行販売10:30～予定(東京・大阪)<br/>
            <br/>
            ※開場の15分前頃よりリハーサルの為、一時グッズ販売エリアへの入場を制限いたします。<br/>
            ※入場後も、グッズ売り場までの行き来∙グッズの購入は可能です。ただし、会場∼開演まではチケットをお持ちでない方のグッズ購入は不可となります。<br/>
            ※当日チケットをお持ちでないお客様でもグッズ販売をご利用いただけますが、開場∼開園時間はご利用いただけない為、開場前か終演後をご利用ください、。<br/>
            ※当日、混雑状況により会場販売時間が変更する場合があります。予めご了承ください。<br/>
            ※販売数には限りがございます。開場前の先行販売の時点で完売の可能性もございます。売り切れの際はご了承ください。<br/>
            ※当日の状況により、購入個数を制限させて頂く場合もございます。<br/>
            ※販売当日は係員の指示に必ず従うようご協力をお願いいたします。<br/>
            <br/>
            <br/>
          </div>
        </div>
      </div>
    </div>
  )
}

function Footer(){
  return(
    <>
    <footer>
      <div className='footerTop'></div>
      <div className='footerBottom'>
        <span>KARA ハン・スンヨン <span className='breakMB'></span>
              JAPAN 2023 ファンミーティングツアー <span className='breakMB'></span>
               『 絆　第1章 』 <span className='breakMB'></span>
        </span>
        <table className='footerInfo'>
          <tbody>
            <tr>
              <th>主催・企画</th>
              <td>(株)HUB JAPAN</td>
            </tr>
            <tr>
              <th>主管</th>
              <td>(株)サイドエー</td>
            </tr>
            <tr>
              <th>制作</th>
              <td>(株)サイドエー</td>
            </tr>
            <tr>
              <th>制作協力</th>
              <td>エイベックス・エンタテインメント(株)、(株)テンプリント</td>
            </tr>
            <tr>
              <th>協力</th>
              <td>(株)YG Entertainment、(株)LOOK KOREA TOUR、ＫＳ情報システム(株)、HITOBITO、TOMODOMO、(株)VitaCafe Japan、株式会社ブレインイノベーション、株式会社ブレインパートナーズ</td>
            </tr>
          </tbody>
        </table>
      </div>
    </footer> 
    </>
  )
}

function SimpleSlider() {
  var settings = {
    autoplay: true,
    speed: 1000,
    dots: true,
    navigator: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          infinite: true,
          centerPadding: "100px",
          lazyLoad: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1023,
        settings: {
          fade: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      <div className="slideImg">
        <img src={ require('./img/topImg.jpg') } />
      </div>
      <div>
        <img src={ require('./img/hsy002.jpeg') } />
      </div>
      <div>
        <img src={ require('./img/hsy003.jpeg') } />
      </div>
      <div>
        <img src={ require('./img/hsy004.jpeg') } />
      </div>
    </Slider>
  );
}

export default App;
